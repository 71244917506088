import React, { useState } from "react";
import { useInput } from "../hooks";
import {
  Navigation,
  Footer,
  ControlPanel,
  DesignPanel,
  RadioButtons,
} from "../components";

import templateArray from "../components/templates/index";

import { options } from "../config";

import "../styles/pages/Home.scss";

const Home = () => {
  const { value: nameValue, input: nameInput } = useInput({
    type: "text",
    name: "name",
  });

  const { value: jobValue, input: jobInput } = useInput({
    type: "text",
    name: "position",
  });

  const { value: mobileValue, input: mobileInput } = useInput({
    type: "text",
    name: "mobile",
  });

  const { value: emailValue, input: emailInput } = useInput({
    type: "text",
    name: "email",
  });

  const [currentValue, setCurrentValue] = useState(options[0]);

  const radioTemplate = (
    <RadioButtons
      name="template"
      options={options}
      setCurrentValue={setCurrentValue}
      currentValue={currentValue}
    />
  );

  const getTemplate = (option: string) => {
    // fIlter template for selected option.
    const filteredTemplate = templateArray.filter((val) => val.id === option);
    // call the functional component.
    const renderTemplate = filteredTemplate[0].template({
      name: nameValue,
      job: jobValue,
      mobile: mobileValue,
      email: emailValue,
    });
    // return the jsx
    return renderTemplate;
  };

  return (
    <div className="home-page">
      <div className="hp-header">
        <Navigation />
      </div>
      <div className="hp-body">
        <div className="hp-body-inner">
          <div className="hp-body-inner-left">
            <ControlPanel
              nameInput={nameInput}
              jobInput={jobInput}
              mobileInput={mobileInput}
              emailInput={emailInput}
              radioTemplate={radioTemplate}
            />
          </div>
          <div className="hp-body-inner-right">
            <DesignPanel template={getTemplate(currentValue)} />
          </div>
        </div>
      </div>
      <div className="hp-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
