import React from "react";
import "../styles/components/Input.scss";

interface IInput {
  type: string;
  name: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Input = ({ type, name, value, setValue }: IInput) => {
  return (
    <div className="input">
      <label className="input-label" htmlFor={name}>
        {name}
      </label>
      <input
        className="input-field"
        value={value}
        name={name}
        onChange={(e) => setValue(e.target.value)}
        type={type}
      />
    </div>
  );
};

export default Input;
