import { useState, useEffect } from "react";
import axios from "axios";
import { Social, Certifications, Data } from "../types";
import { apiTemplateLink, FETCH_HEADERS } from "../config";

export default function useTemplate(type: string) {
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [socials, setSocials] = useState<Social[] | null>(null);
  const [badgesTop, setBadgesTop] = useState<Certifications[] | null>(null);
  const [badgesBottom, setBadgesBottom] = useState<Certifications[] | null>(
    null
  );
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const getTemplateData = async (): Promise<Data | null> => {
      try {
        // call API
        const data = await axios.get(`${apiTemplateLink}`, {
          headers: FETCH_HEADERS,
        });

        // filter for selected template
        const selectedData = data.data.filter((val: Data) => val.uid === type);
        // destructure information
        const { companyDetails, socialMediaLinks, certifications } =
          selectedData[0];
        // split certifications to top and bottom.
        const bottomBadges: Certifications[] = certifications.splice(3, 5);

        setWebsite(companyDetails.website);
        setAddress(companyDetails.address);
        setPhone(companyDetails.phone);
        setSocials(socialMediaLinks);
        setBadgesTop(certifications);
        setBadgesBottom(bottomBadges);
        setLogo(selectedData[0].logo.url);

        return data.data;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return null;
      }
    };

    getTemplateData();
  }, [type]);

  return {
    website,
    address,
    phone,
    socials,
    badgesTop,
    badgesBottom,
    logo,
  };
}
