import React from "react";
import "../styles/components/ControlPanel.scss";

interface IControlPanel {
  nameInput: JSX.Element;
  jobInput: JSX.Element;
  mobileInput: JSX.Element;
  emailInput: JSX.Element;
  radioTemplate: JSX.Element;
}

const ControlPanel = ({
  nameInput,
  jobInput,
  mobileInput,
  emailInput,
  radioTemplate,
}: IControlPanel) => {
  return (
    <div className="control-panel">
      <form className="cp-submission-form">
        {nameInput}
        {jobInput}
        {mobileInput}
        {emailInput}
        {radioTemplate}
      </form>
    </div>
  );
};

export default ControlPanel;
