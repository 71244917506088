import React from "react";
import { ITemplate } from "../../interfaces";
import "../../styles/components/templates/RORTemplate.scss";
import { useStateTemplate } from "../../hooks/index";
import { apiLink, ROR } from "../../config";
import { Certifications, Social } from "../../types";

const RORTemplate = ({ name, job, mobile, email }: ITemplate) => {
  const { website, address, phone, socials, badgesTop, badgesBottom, logo } =
    useStateTemplate(ROR);

  return (
    <table>
      <thead>
        <tr>
          <td>
            <img
              className="ROR-logo"
              src={logo !== null ? `${apiLink}${logo}` : ""}
              alt="ROR logo"
              width="300"
            />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="ROR-user">
            <div className="ROR-name">{name}</div>
            <div className="ROR-job">{job}</div>
          </td>
        </tr>
        <tr>
          <td className="ROR-info mobile">
            <span className="bi-key">Mobile</span>
            <span className="bi-divider"> | </span>
            <a className="bi-value" href={`tel:[${mobile.replace(/\s/g, "")}]`}>
              {mobile}
            </a>
          </td>
        </tr>
        <tr>
          <td className="ROR-info">
            <span className="bi-key">Phone</span>
            <span className="bi-divider"> | </span>
            <a className="bi-value" href={`tel:[${phone.replace(/\s/g, "")}]`}>
              {phone}
            </a>
          </td>
        </tr>
        <tr>
          <td className="ROR-info">
            <span className="bi-key">Email</span>
            <span className="bi-divider"> | </span>
            <a
              className="bi-value"
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
            >
              {email}
            </a>
          </td>
        </tr>
        <tr>
          <td className="ROR-info">
            <span className="bi-key">Website</span>
            <span className="bi-divider"> | </span>
            <a
              className="bi-value"
              href={website}
              target="_blank"
              rel="noreferrer"
            >
              {website}
            </a>
          </td>
        </tr>
        <tr>
          <td className="ROR-info">
            <span className="bi-key">Address</span>
            <span className="bi-divider"> | </span>
            <a
              className="bi-value"
              href={socials != null ? socials[5].url : ""}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </td>
        </tr>

        <tr>
          <table className="ROR-sm">
            {(socials || []).map(({ url, linkLogo }: Social) => {
              return (
                <td className="ROR-sm-link-container" key={linkLogo[0].url}>
                  <a
                    className="ROR-sm-link"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="ROR-sm-link-img"
                      src={`${apiLink}${linkLogo[0].url}`}
                      alt="social media"
                      height="29"
                    />
                  </a>
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="ROR-badges">
            {(badgesTop || []).map((obj: Certifications) => {
              return (
                <td className="ROR-badges-link-img-container" key={obj.id}>
                  <img
                    className="ROR-badges-link-img"
                    src={`${apiLink}${obj.url}`}
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr>
        <tr>
          <table className="ROR-badges">
            {(badgesBottom || []).map((obj: Certifications) => {
              return (
                <td className="ROR-badges-link-img-container" key={obj.id}>
                  <img
                    className="ROR-badges-link-img"
                    src={`${apiLink}${obj.url}`}
                    alt="badges"
                    height="60"
                  />
                </td>
              );
            })}
          </table>
        </tr>
      </tbody>
    </table>
  );
};

export default RORTemplate;
