import BAVTemplate from "./BAVTemplate";
import BTTemplate from "./BTTemplate";
import RORTemplate from "./RORTemplate";
import RSTemplate from "./RSTemplate";

import { BAV, ROR, RS, BT } from "../../config";

export default [
  {
    id: BAV,
    template: BAVTemplate,
  },
  {
    id: ROR,
    template: RORTemplate,
  },
  {
    id: RS,
    template: RSTemplate,
  },
  {
    id: BT,
    template: BTTemplate,
  },
];
