const apiLink = "https://strapi-rorsignature.designedanddeveloped.com.au";
const apiTemplateLink =
  "https://strapi-rorsignature.designedanddeveloped.com.au/Signature-Templates";

export { apiLink, apiTemplateLink };

export const BAV = "BAV";
export const ROR = "ROR";
export const RS = "RS";
export const BT = "BT";
export const options = [BAV, ROR, RS, BT];

// For post
export const JWT_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQzMDAwMzgxLCJleHAiOjE2NDU1OTIzODF9.OG9KhYNdxw-6soikNMEpdEoMtEpRRszs8aUJs3FQ2tc";

export const FETCH_HEADERS = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${JWT_TOKEN}`,
};

export const SUCCESS_STATUS = 200;
