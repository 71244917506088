/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { ITemplate } from "../../interfaces";
import "../../styles/components/templates/RORTemplate.scss";
import { useStateTemplate } from "../../hooks/index";
import { RS } from "../../config";

const RSTemplate = ({ name, job, mobile, email }: ITemplate) => {
  const { website, address, phone, socials, badgesTop, badgesBottom, logo } =
    useStateTemplate(RS);

  return <table />;
};

export default RSTemplate;
