import React from "react";
import "../styles/components/Navigation.scss";

const Navigation = () => {
  return (
    <nav className="navigation">
      <div className="nav-inner">ROR Signatures</div>
    </nav>
  );
};

export default Navigation;
